import { render, staticRenderFns } from "./BreadcrumbNav.vue?vue&type=template&id=49ccde8d&scoped=true"
import script from "./BreadcrumbNav.vue?vue&type=script&lang=js"
export * from "./BreadcrumbNav.vue?vue&type=script&lang=js"
import style0 from "./BreadcrumbNav.vue?vue&type=style&index=0&id=49ccde8d&prod&lang=scss&scoped=true"
import style1 from "./BreadcrumbNav.vue?vue&type=style&index=1&id=49ccde8d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ccde8d",
  null
  
)

export default component.exports