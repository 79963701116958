BreadcrumbNav<template>
    <div class="breadcrumbNav">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item,index) in nav" :key="index" :to="{ path: item.path }">
                <span class='breadcrumb-item'>{{item.name}}</span>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
import Core from 'core';


export default {
    components: {
    },
    props: {
        list:{
            type: Array,
        }
    },
    data() {
        return {
            userType: Core.Data.getUserType(),
            classify: '',
            nav: [],
        }
    },
    watch: {
        $route(to, from) {
            console.log('to:', to)
            let newRoute = {
                name: to.meta.title,
                path: to.fullPath,
                classify: to?.meta?.classify || 1
            }
            let blockName = to.path.split('/')
            if (!to.meta.hidden) {

                this.nav = []
                this.nav.push(newRoute)
                switch (blockName[1]) {
                    case 'plan':
                        this.nav.unshift({
                            name: '教学计划',
                            path: to.fullPath,
                            classify: 1,
                        })
                        break;
                    case 'account':
                        this.nav.unshift({
                            name: '账号管理',
                            path: to.fullPath,
                            classify: 2,
                        })
                        break;
                    case 'operate-quotient':
                        this.nav.unshift({
                            name: '发育商测评',
                            path: to.fullPath,
                            classify: 2,
                        })
                        break;
                    case 'online-course':
                        this.nav.unshift({
                            name: '课程管理',
                            path: to.fullPath,
                            classify: 3,
                        })
                        break;
                    default:
                        break;
                }
            } else {
                for (let i = 0; i < this.nav.length; i++) {
                    if (this.nav[i].name === to.meta.title) {
                        this.nav.splice(i)
                    }
                }
                this.nav.push(newRoute)
            }

            Core.Data.setBreadcrumbNav(this.nav)
        }
    },
    computed: {},
    created() {
        let newRoute = {
            name: this.$route.meta.title,
            path: this.$route.fullPath,
            classify: this.$route.meta?.classify || 1
        }
        if (!this.nav.length) {
            this.nav = [newRoute]
            Core.Data.setBreadcrumbNav(this.nav)
            if (this.nav[this.nav.length-1].name !== '资源详情' && this.$route.path === '/resources/view') {
                let newRoute = {
                    name: this.$route.meta.title,
                    path: this.$route.fullPath,
                    classify: 1
                }
                this.nav.push(newRoute)
            }
        }
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.breadcrumbNav {
    @include ftb;
    height:60px;
    background:rgba(255,255,255,1);
    padding: 0 14px;
    opacity:0.6;
}
.el-breadcrumb{
    line-height: 22px;
    .breadcrumb-item{
        font-weight:400;
        cursor: pointer;
    }
}
</style>

<style lang="scss" scoped>
.breadcrumbNav {
    .el-breadcrumb{
        .el-breadcrumb__item{
            .breadcrumb-item {
                color: #8090A6;
            }
            &:last-child{
                .breadcrumb-item{
                    color: #363D42;
                }
            }
            .el-breadcrumb__separator{
                margin: 0 8px;
                color: #D5DEE6;
            }
        }
    }
}
</style>
